import axios from 'axios';
import {
  getUserNotificationsByDateAndStatus,
  getAllHefestoLayerConstants,
  getAllHefestoRoleConstants
} from '../graphql/queries';
import { updateUserNotification, addUserToGroup } from '../graphql/mutation';
import {
  NotificationProject,
  NotificationRequestInput,
  LAYER_HACKER,
  FilterRequests,
  LIMIT
} from '../interfaces/userRequestI';

const URL = process.env.GRAPHQLURL || '';

export const userRequestApi = axios.create({
  baseURL: URL,
  headers: { 'Content-Type': 'application/graphql' }
});

export const getUserRequestsDS = async (ogmioToken: string, filter: FilterRequests, dateRangeToEpoch: string) => {
  const input: NotificationProject = {
    to: LAYER_HACKER,
    date: dateRangeToEpoch,
    page: filter.page || 1, // Asegúrate de incluir el número de página actual
    limit: filter.limit || LIMIT // Puedes ajustar este valor según tus necesidades
  };

  if (filter.status) {
    input.currentstatus = filter.status;
  }
  if (filter.email) {
    input.email = filter.email;
  }
  if (filter.role) {
    input.role = filter.role;
  }
  if (filter.layer) {
    input.from = filter.layer;
  }
  if (filter.role) {
    input.role = filter.role;
  }

  const graphql = JSON.stringify({
    query: getUserNotificationsByDateAndStatus,
    variables: {
      input: input
    }
  });

  // Configurar el token de autorización para esta petición específica
  const headers = {
    Authorization: ogmioToken
  };

  try {
    const { data } = await userRequestApi.post('', graphql, { headers });
    return data.data.getUserNotificationsByDateAndStatus;
  } catch (error) {
    // Aquí se maneja el error de manera específica antes de lanzar una excepción
    console.error('Error fetching user requests: ', error);
    // Podrías lanzar un nuevo error con más contexto o manejar el estado de error de manera diferente
    throw new Error(`Error fetching user requests: ${error.message}`);
  }
};

export const rejectRequest = async (ogmioToken: string, input: NotificationRequestInput) => {
  const graphql = JSON.stringify({
    query: updateUserNotification,
    variables: {
      input: input
    }
  });

  // Configurar el token de autorización para esta petición específica
  const headers = {
    Authorization: ogmioToken
  };

  try {
    const { data } = await userRequestApi.post('', graphql, { headers });

    return data.data.updateUserNotification;
  } catch (error) {
    // Aquí se maneja el error de manera específica antes de lanzar una excepción
    console.error('Error reject user requests: ', error);
    // Podrías lanzar un nuevo error con más contexto o manejar el estado de error de manera diferente
    throw new Error(`Error reject user requests: ${error.message}`);
  }
};

export const approveRequest = async (ogmioToken: string, input: NotificationRequestInput) => {
  const graphql = JSON.stringify({
    query: addUserToGroup,
    variables: {
      input: input
    }
  });

  // Configurar el token de autorización para esta petición específica
  const headers = {
    Authorization: ogmioToken
  };

  try {
    const { data } = await userRequestApi.post('', graphql, { headers });

    return data.data.addUserToGroup;
  } catch (error) {
    // Aquí se maneja el error de manera específica antes de lanzar una excepción
    console.error('Error approve user requests: ', error);
    // Podrías lanzar un nuevo error con más contexto o manejar el estado de error de manera diferente
    throw new Error(`Error approve user requests: ${error.message}`);
  }
};

export const getAllLayer = async (ogmioToken: string) => {
  const graphql = JSON.stringify({
    query: getAllHefestoLayerConstants
  });

  // Configurar el token de autorización para esta petición específica
  const headers = {
    Authorization: ogmioToken
  };

  try {
    const { data } = await userRequestApi.post('', graphql, { headers });
    return data.data.getAllHefestoLayerConstants;
  } catch (error) {
    // Aquí se maneja el error de manera específica antes de lanzar una excepción
    console.error('Error getAllLayer: ', error);
    // Podrías lanzar un nuevo error con más contexto o manejar el estado de error de manera diferente
    throw new Error(`Error getAllLayer: ${error.message}`);
  }
};

export const getAllRole = async (ogmioToken: string) => {
  const graphql = JSON.stringify({
    query: getAllHefestoRoleConstants
  });

  // Configurar el token de autorización para esta petición específica
  const headers = {
    Authorization: ogmioToken
  };

  try {
    const { data } = await userRequestApi.post('', graphql, { headers });
    return data.data.getAllHefestoRoleConstants;
  } catch (error) {
    // Aquí se maneja el error de manera específica antes de lanzar una excepción
    console.error('Error getAllRole : ', error);
    // Podrías lanzar un nuevo error con más contexto o manejar el estado de error de manera diferente
    throw new Error(`Error getAllRole : ${error.message}`);
  }
};

import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import BaseComponent from './components/base-component';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './components/utils/auth-context';
import './tailwind.scss';

export default function Root(): React.JSX.Element {
  const queryClient = new QueryClient();

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Toaster position="top-center" reverseOrder={false} />
          <BaseComponent />
        </AuthProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

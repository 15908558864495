import * as React from 'react';
import { useState } from 'react';
import { useUserRequests, useRejectRequest, useApproveRequest } from '../hooks/useUserRequests';
import { Filter } from './filter';
import { toolIcon } from '../utils/toolIcons';
import { toast } from 'react-hot-toast';
import { NotificationRequestInput, NotificationStatus, LAYER_HACKER, LIMIT } from '../interfaces/userRequestI';
import CustomLoader from '../../common/custom-loader';
import { RejectModal } from '../../common/rejectModal';
import { InformationListModal } from '../../common/InformationListModal';
import Pagination from './pagination';
import { FaCircleUser, FaExclamation, FaFileLines } from 'react-icons/fa6';

export function ViewTable(): React.JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la visibilidad del modal
  const [selectedUserId, setSelectedUserId] = useState(null); //State que guarda  el id de usuario seleccionado en el filtro
  const [approvingUserId, setApprovingUserId] = useState(null); // State que maneja el id de  usuario a aprobar
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(LIMIT); // Asumiendo que deseas 10 items por página por defecto

  //  Traer las solicitudes de los usuarios y el método para rechazar una solicitud
  // Cuando llames a useUserRequests, pasa los filtros actuales
  const { isLoading, data: userRequests, error } = useUserRequests({ ...filters, page: currentPage, limit: pageSize }); // data del retorno del hook useUserRequest
  const { mutate: rejectRequestMutate } = useRejectRequest(); // mutate del hook userRejectRequest
  const { mutate: approveRequestMutate } = useApproveRequest(); // mutate del hook useApproveRequest

  const [selectedUserErrors, setSelectedUserErrors] = useState([]);
  const [selectedUserTicket, setSelectedUserTicket] = useState('');
  const [modalTitle, setModaltitle] = useState('');
  const [selectedUserComments, setSelectedUserComments] = useState('');
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalCommentsOpen, setIsModalCommentsOpen] = useState(false); // Estado para controlar la visibilidad del modal

  const handleFilterChange = newFilters => {
    if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
      setFilters(prevFilters => ({
        ...prevFilters,
        ...newFilters
      }));
      setCurrentPage(1); // Solo resetea la paginación si los filtros han cambiado
    }
  };

  // Manejador para actualizar la página actual
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = size => {
    setPageSize(size);
  };

  // Maneja el proceso de abrir la Modal
  const openModal = userId => {
    setIsModalOpen(true);
    setSelectedUserId(userId);
  };

  // Maneja el proceso de cerrar la Modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const execMutationReject = message => {
    if (selectedUserId) {
      const input: NotificationRequestInput = {
        id: selectedUserId,
        userID: LAYER_HACKER,
        newStatus: NotificationStatus.rejected,
        message: message
      };
      rejectRequestMutate(input);
    }
  };

  // Definición del manejador para el rechazo, que llama a la función de mutación con el id del usuario
  const rejectHandler = (userId: string, currentstatus: string) => {
    // Verifica si el estado actual es 'rejected', si es así, evita realizar la acción
    if (currentstatus === NotificationStatus.rejected) {
      toast.error('Esta solicitud ya ha sido rechazada y no se puede rechazar nuevamente.');
      return;
    }

    // Si el estado es 'pending' o 'failed', permite realizar la acción
    if (currentstatus === NotificationStatus.pending || currentstatus === NotificationStatus.failed) {
      openModal(userId);
    } else {
      // Para cualquier otro estado, podrías decidir no permitir la acción o manejarlo de otra manera
      toast.error('Esta solicitud no se puede rechazar en su estado actual.');
    }
  };

  const approveHandler = (userId: string, currentstatus: string) => {
    // Si el estado es 'pending' o 'failed', permite realizar la acción
    if (
      currentstatus === NotificationStatus.pending ||
      currentstatus === NotificationStatus.failed ||
      currentstatus === NotificationStatus.rejected
    ) {
      if (userId) {
        setApprovingUserId(userId);
        const input: NotificationRequestInput = {
          id: userId,
          userID: LAYER_HACKER,
          newStatus: NotificationStatus.approved
        };
        approveRequestMutate(input, {
          onSettled: () => {
            setApprovingUserId(null); // Finalizar el proceso de aprobación
          }
        });
      }
    } else {
      // Para cualquier otro estado, podrías decidir no permitir la acción o manejarlo de otra manera
      toast.error('Esta solicitud no se puede procesar en su estado actual.');
    }
  };

  // Nuevo estado para almacenar los resultados de rand() por usuario
  //const [userImages, setUserImages] = useState({});

  // useEffect(() => {
  //   // Genera un resultado de rand() por cada usuario solo una vez
  //   const newUserImages = {};
  //   userRequests?.data?.forEach(user => {
  //     newUserImages[user.id] = rand();
  //   });
  //   setUserImages(newUserImages);
  // }, [userRequests]);

  const getPendingAuditFrom = (audit: Array<{ statusNew: string; from: string }>) => {
    if (!Array.isArray(audit)) return 'N/A';
    const pendingAudit = audit.find(a => a.statusNew === NotificationStatus.pending);
    return pendingAudit ? pendingAudit.from : 'N/A';
  };

  const getErrorsAudit = audit => {
    if (!Array.isArray(audit)) {
      return ['There are no errors stored, retry'];
    }

    // Clonar el array audit y revertirlo
    const reversedAudit = [...audit].reverse();

    // Buscar el último registro con estado "failed" y obtener los errores
    const lastRelevantAudit = reversedAudit.find(
      entry => entry.statusNew === NotificationStatus.failed || entry.statusNew === NotificationStatus.approved
    );

    // Verificar si lastFailedAudit existe y tiene errores que no son nulos
    return lastRelevantAudit && lastRelevantAudit.errors
      ? lastRelevantAudit.errors
      : ['There are no errors stored, retry'];
  };

  const selectRow = (userId, currentstatus) => {
    // Si el estado es 'failed', permite realizar la acción
    if (currentstatus === NotificationStatus.failed || currentstatus === NotificationStatus.approved) {
      // Obtener los errores del usuario seleccionado
      const user = userRequests.data.find(user => user.id === userId);

      const errors = user ? getErrorsAudit(user.audit) : ['There are no errors stored, retry'];

      setSelectedUserErrors(errors); // Establece los errores del usuario seleccionado
      if (userId !== selectedUserId) {
        setSelectedUserId(userId); // Establece el usuario seleccionado solo si es diferente
      }
      setModaltitle("Errores al procesar la solicitud")
      openModalInformation(userId); // Abre la modal correspondiente
    }
  };

  const showComments = (userId, ticket, comments) => {
    // Si el estado es 'failed', permite realizar la acción
    if (ticket != '') {
      setSelectedUserTicket(ticket);
      setSelectedUserComments(comments);
      openModalInformation(userId);
      setModaltitle("Comentarios de la solicitud")
    }
  };

  // Maneja el proceso de abrir la Modal
  const openModalInformation = userId => {
    setIsModalFailedOpen(true);
  };

  // Maneja el proceso de cerrar la Modal
  const closeModalInformation = () => {
    setIsModalFailedOpen(false);
    setSelectedUserTicket('')
  };


  const StatusIndicator = ({ status, hasErrors }) => {
    let bgColor = '';
    let ping = '';

    switch (status) {
      case NotificationStatus.pending:
        bgColor = 'bg-gray-500';
        break;
      case NotificationStatus.approved:
        bgColor = 'bg-green-500';
        break;
      case NotificationStatus.failed:
        bgColor = 'bg-yellow-500';
        break;
      case NotificationStatus.rejected:
        bgColor = 'bg-red-500';
        break;
      default:
        bgColor = '';
    }

    if ((status === NotificationStatus.failed || status === NotificationStatus.approved) && hasErrors) {
      ping = 'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75';
    }

    return (
      <div className="flex items-center capitalize">
        <span className="relative flex h-2.5 w-2.5 me-2">
          <span className={`${bgColor} ${ping}`}></span>
          <span className={`relative inline-flex rounded-full h-2.5 w-2.5 ${bgColor}`}></span>
        </span>
        {status}
        {hasErrors}
      </div>
    );
  };

  const DetailsIndicator = ({ ticket }) => {
    if (ticket != '') {
      return <FaFileLines size={25} />;
    } else {
      return '';
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full pt-2 px-2">
      <div className="w-11/12">
        <Filter onFilterChange={handleFilterChange} />
        {/* Renderiza la tabla con los datos filtrados */}
        <div className="mt-8 mb-8 flow-root bg-white rounded-xl">
          <div className="m-8">
            <div className="mt-4 bg-white rounded-xl overflow-hidden shadow-lg overflow-x-auto">
              <table className="min-w-full text-sm text-left w-full rtl:text-right text-gray-500">
                <thead className="text-base text-gray-700 bg-gray-50 border-b">
                  <tr>
                    <th scope="col" className="px-6 py-2">
                      Requested By
                    </th>
                    <th scope="col" className="px-6 py-2">
                      From
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Tools
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Role
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Details
                    </th>
                    <th scope="col" className="px-6 py-2">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        <div className="flex justify-center py-60">
                          <CustomLoader height={'50'} width={'50'} color={'vulcanus_ghost_white-500'} />
                        </div>
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan={5} className="text-center py-60">
                        Error: {(error as Error)?.message}
                      </td>
                    </tr>
                  ) : userRequests && Array.isArray(userRequests.data) && userRequests.data.length > 0 ? (
                    userRequests.data.map(user => (
                      <tr key={user.id} className={`bg-white border-b hover:bg-gray-200 `}>
                        <th scope="row" className="flex items-center px-6 py-2 text-gray-900 whitespace-nowrap">
                          {/* Funcion futura para consumir la foto de perfil de los members */}
                          {/* <img
                              className="w-10 h-10 rounded-full"
                              src={userImages[user.id]} // Usa el resultado precalculado de rand() almacenado en userImages
                              alt="User image"
                            /> */}
                          <FaCircleUser className="w-10 h-10 rounded-full" />
                          <div className="ps-3">
                            <div className="normal-case font-semibold">{user.from.replaceAll('_', ' ')}</div>
                            <div className="font-normal text-gray-500">{user.request.id}</div>
                            <div className="font-normal text-gray-500">{user.request.email}</div>
                          </div>
                        </th>
                        <td className="px-6 py-2 capitalize">{getPendingAuditFrom(user.audit)}</td>
                        <td className="px-6 py-2">
                          <div className="flex gap-2">
                            {user.request.tools &&
                              user.request.tools
                                .filter(tool => tool.value === 'true')
                                .map((tool, toolIndex) => <div key={toolIndex}>{toolIcon(tool.name)}</div>)}
                          </div>
                        </td>
                        <td className="px-6 py-2 capitalize">{user.request.roleId.toLowerCase()}</td>
                        <td
                          className={`px-6 py-2 ${
                            (user.currentstatus === NotificationStatus.failed ||
                              user.currentstatus === NotificationStatus.approved) &&
                            getErrorsAudit(user.audit).length > 0 &&
                            getErrorsAudit(user.audit)[0] !== 'There are no errors stored, retry'
                              ? 'cursor-zoom-in'
                              : ''
                          }`}
                          onClick={() => selectRow(user.id, user.currentstatus)}
                        >
                          <StatusIndicator
                            status={user.currentstatus}
                            hasErrors={
                              getErrorsAudit(user.audit).length > 0 &&
                              getErrorsAudit(user.audit)[0] !== 'There are no errors stored, retry'
                            }
                          />
                        </td>
                        <td
                          className={`px-6 py-2" ${user.ticket != '' ? 'cursor-zoom-in' : ''}`}
                          onClick={() => showComments(user.id, user.ticket, user.comments)}
                        >
                          <div className="flex space-x-4 ">
                            <DetailsIndicator ticket={user.ticket} />
                          </div>
                        </td>
                        <td className="px-6 py-2">
                          <div className="flex space-x-4">
                            <button
                              className="btn-approve"
                              onClick={() => approveHandler(user.id, user.currentstatus)}
                              disabled={approvingUserId !== null} // Desactivar si hay un proceso de aprobación en curso
                            >
                              {approvingUserId === user.id ? (
                                <div className="flex justify-center items-center w-full h-full">
                                  <svg
                                    className="animate-spin h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              ) : (
                                'Approve'
                              )}
                            </button>
                            <button
                              className="btn-reject"
                              disabled={approvingUserId !== null}
                              onClick={() => rejectHandler(user.id, user.currentstatus)}
                            >
                              Reject
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center py-60">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {userRequests && Array.isArray(userRequests.data) && userRequests.data.length > 0 ? (
            <div>
              <Pagination
                pageIndex={userRequests.pageIndex}
                pageSize={pageSize}
                totalPages={userRequests.totalPages}
                totalItems={userRequests.totalItems}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
              />

              {/* Modal */}
              <RejectModal
                isOpen={isModalOpen}
                onClose={closeModal}
                description={`Motivo por el que se rechaza la solicitud:`}
                title="Mensaje de Rechazo"
                actionButtonText="Reject"
                secondaryButtonText="Cancel"
                executeMutation={execMutationReject}
                loading={isLoading}
              />
              <InformationListModal
                isOpen={isModalFailedOpen}
                onClose={closeModalInformation}
                title={modalTitle}
                errors={selectedUserErrors}
                ticket={selectedUserTicket}
                comments={selectedUserComments}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import * as React from 'react';

interface ErrorInfo {
  component: string;
  errorMessage: string;
}

export interface InformationListModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  errors?: string[];
  ticket?: any;
  comments?: any;
}

export const InformationListModal = (props: InformationListModalProps) => {
  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </Transition.Child>

        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-2xl w-full">
              <div className="p-4">
                <div className="text-lg font-semibold text-gray-800">{props.title}</div>
                <div className="mt-4 md:grid-cols-2">
                  <GenericSection fields={props} />
                </div>
                <div className="flex items-center justify-end py-4 border-t border-gray-200 rounded-b space-x-4">
                  <button onClick={props.onClose} className="btn-reject">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const GenericSection = listErrors => {
  const parseError = (errorMsg: string): ErrorInfo => {
    const [component, errorMessage] = errorMsg
      .replace('[ERROR]', '')
      .split('::')
      .map(part => part.trim());
    return { component, errorMessage };
  };
  var listItems;
  if (listErrors.fields.ticket != '') {
    return (
      <React.Fragment>
        <div className="grid md:grid-cols-3 border-t py-2 border-gray-200">
          <p className="text-sm text-red-950">Ticket</p>
          <p className="col-span-2 text-sm text-gray-500">{listErrors.fields.ticket}</p>
        </div>
        <div className="grid md:grid-cols-3 border-t py-2 border-gray-200">
          <p className="text-sm text-red-950">Comments</p>
          <p className="col-span-2 text-sm text-gray-500">
            {listErrors.fields.comments != '' ? listErrors.fields.comments : 'No comments...'}
          </p>
        </div>
      </React.Fragment>
    );
  } else {
    listItems = listErrors.fields.errors.map((error, index) => (
      <div key={index} className="grid grid-cols-3 border-t py-2 border-gray-200">
        <p className="text-sm text-red-950">{parseError(error).component}</p>
        <p className="col-span-2 text-sm text-gray-500">{parseError(error).errorMessage}</p>
      </div>
    ));
  }
  return listItems;
};

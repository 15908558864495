import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getUserRequestsDS, rejectRequest, approveRequest, getAllLayer, getAllRole } from '../services/user-request';
import { useAuth } from '../utils/auth-context';
import { toast } from 'react-hot-toast';
import { NotificationRequestInput, FilterRequests, DateRangeFilter } from '../interfaces/userRequestI';
import { FaCircleUser } from 'react-icons/fa6';

export const useUserRequests = (filters: FilterRequests) => {
  const { ogmioToken } = useAuth();

  const dateRangeToEpoch = dateRange => {
    let day;
    switch (dateRange) {
      case DateRangeFilter.oneMonth: {
        day = 30;
        break;
      }
      case DateRangeFilter.twoMonth: {
        day = 60;
        break;
      }
      case DateRangeFilter.oneYear: {
        day = 360;
        break;
      }
      default: {
        day = 7;
        break;
      }
    }
    // Implementa la lógica para convertir el rango de fechas en una fecha epoch
    return (Math.floor(Date.now() / 1000) - day * 24 * 60 * 60).toString();
  };

  return useQuery({
    queryKey: ['getUserRequest', ogmioToken, filters],
    queryFn: () => getUserRequestsDS(ogmioToken, filters, dateRangeToEpoch(filters.dateRange)),
    enabled: !!ogmioToken && !!filters // Solo ejecutar la consulta si ogmioToken y filters están presentes
    //refetchOnWindowFocus: false // Deshabilita re-fetching al cambiar el foco de la ventana
  });
};

export const useRejectRequest = () => {
  const { ogmioToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation((input: NotificationRequestInput) => rejectRequest(ogmioToken, input), {
    onSuccess: () => {
      toast.success('Successfully!');
      queryClient.invalidateQueries(['getUserRequest']);
    },
    onError: () => {
      toast.error('Ocurrió un error al rechazar el pedido');
    }
  });
};

export const useApproveRequest = () => {
  const { ogmioToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation((input: NotificationRequestInput) => approveRequest(ogmioToken, input), {
    onSuccess: data => {
      if (data.statusCode === 200) {
        toast.success('Operación realizada con éxito');
      } else if (data.errors.length > 0) {
        toast.custom(
          t => (
            <div
              className={`${
                t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 overflow-hidden`}
            >
              <div className="flex-1 w-auto p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 pt-0.5">
                    <FaCircleUser className="w-10 h-10 rounded-full" />
                  </div>
                  <div className="ml-3 flex-1">
                    {/* <div className="grid grid-cols-3"> */}
                    <p className="col-span-3 text-sm font-semibold text-red-950">Errores al aprobar la solicitud: </p>
                    {/* </div> */}
                    <div className="mt-2 text-sm text-gray-500 space-y-2 w-auto">
                      {data.errors.map((error, index) => {
                        // Supongamos que cada mensaje de error sigue el formato "[ERROR] source:: mensaje"
                        const [errorType, message] = error.message
                          .replace('[ERROR]', '')
                          .split('::')
                          .map(part => part.trim());
                        return (
                          <div key={index} className="grid grid-cols-3 border-b py-2">
                            <p className="font-bold text-red-950">{errorType}</p>
                            <p className="col-span-2 text-sm text-gray-500 ">{message}</p>
                          </div>
                          //   <div key={index} className="flex flex-col border-b">
                          //   <span className="font-bold text-red-950">{errorType}:</span>
                          //   <span>{message}</span>
                          // </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="border border-transparent h-2 w-2 rounded-full p-4 flex items-center justify-center text-sm font-medium bg-red-700 hover:bg-red-800  text-white hover:text-white "
                >
                  X
                </button>
              </div>
            </div>
          ),
          { duration: 38000, style: { maxWidth: 'none' } }
        ); // Duración extendida a 10 segundos para el toast custom
      } else {
        toast.error('Ocurrió un error inesperado', { duration: 8000 });
      }
      queryClient.invalidateQueries(['getUserRequest']);
    },
    onError: () => {
      toast.error('Ocurrió un error al aprobar el pedido', { duration: 8000 });
    }
  });
};

export const useGetAllLayer = () => {
  const { ogmioToken } = useAuth();

  return useQuery({
    queryKey: ['getAllLayer', ogmioToken],
    queryFn: () => getAllLayer(ogmioToken),
    enabled: !!ogmioToken, // Solo ejecutar la consulta si ogmioToken está presente
    staleTime: Infinity // Los datos de capas no necesitan ser refrescados constantemente
  });
};

export const useGetAllRole = () => {
  const { ogmioToken } = useAuth();

  return useQuery({
    queryKey: ['getAllRole', ogmioToken],
    queryFn: () => getAllRole(ogmioToken),
    enabled: !!ogmioToken, // Solo ejecutar la consulta si ogmioToken está presente
    staleTime: Infinity // Los datos de roles no necesitan ser refrescados constantemente
    // staleTime: 1000 * 60 * 60, // Datos considerados frescos por 1 hora
    // cacheTime: 1000 * 60 * 60 * 24, // Datos permanecen en caché durante 24 horas
  });
};

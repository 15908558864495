const Pagination = ({ pageIndex, pageSize, totalPages, totalItems, onPageChange, onPageSizeChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-end normal-case font-semibold text-sm px-6 py-2">
      <div className="flex justify-between items-center px-2">
        <div className="flex items-center">
          <select
            className="border border-gray-300 px-4 py-1 rounded-md text-sm w-20 text-gray-700"
            value={pageSize}
            onChange={e => onPageSizeChange(Number(e.target.value))}
          >
            {[7, 15, 30, 45, 50].map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          <span className="px-4 text-gray-700">
            Page {pageIndex} of {totalPages}
          </span>
        </div>
        <div className="flex items-center">
          <button
            className={`px-2 py-1 mx-1 rounded-md ${
              pageIndex <= 1 ? 'bg-gray-400 text-white' : 'bg-vulcanus_paynes_gray-500 text-white'
            }`}
            disabled={pageIndex <= 1}
            onClick={() => onPageChange(pageIndex - 1)}
          >
            Prev
          </button>
          {pageNumbers.map(number => (
            <button
              key={number}
              className={`px-2 py-1 mx-1 rounded-md ${
                pageIndex === number ? 'bg-vulcanus_paynes_gray-500 text-white' : 'bg-gray-400 text-white'
              }`}
              onClick={() => onPageChange(number)}
            >
              {number}
            </button>
          ))}
          <button
            className={`px-2 py-1 mx-1 rounded-md ${
              pageIndex >= totalPages ? 'bg-gray-400 text-white' : 'bg-vulcanus_paynes_gray-500 text-white'
            }`}
            disabled={pageIndex >= totalPages}
            onClick={() => onPageChange(pageIndex + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

export const updateUserNotification = /* GraphQL */ `
  mutation updateUserNotification($input: NotificationRequestInput) {
    updateUserNotification(input: $input) {
      id
      from
      to
      currentdate
      message
      currentstatus
      audit {
        statusPrev
        statusNew
        date
        from
      }
    }
  }
`;

export const addUserToGroup = /* GraphQL */ `
  mutation addUserToGroup($input: NotificationRequestInput) {
    addUserToGroup(input: $input) {
      errors {
        type
        message
      }
      statusCode
    }
  }
`;

import { FaGithubAlt, FaSlack, FaWindows, FaConfluence, FaFrog, FaDiceD20 } from 'react-icons/fa6';
import { SiSonarqube } from 'react-icons/si';

// Función auxiliar para mapear el nombre de la herramienta a un ícono y su tooltip
export const toolIcon = (toolName: string) => {
  const icons = {
    github_enterprise: { component: FaGithubAlt, tooltip: 'GitHub Enterprise' },
    github_emu: { component: FaGithubAlt, tooltip: 'GitHub EMU' },
    github_com: { component: FaGithubAlt, tooltip: 'GitHub.com' },
    ldap: { component: FaWindows, tooltip: 'LDAP' },
    confluence: { component: FaConfluence, tooltip: 'Confluence' },
    ogmio: { component: FaDiceD20, tooltip: 'Ogmio' },
    artifactory: { component: FaFrog, tooltip: 'Artifactory' },
    slack: { component: FaSlack, tooltip: 'Slack' },
    sonarqube: { component: SiSonarqube, tooltip: 'SonarQube' }
  };

  const IconComponent = icons[toolName]?.component;
  const tooltipText = icons[toolName]?.tooltip;

  return IconComponent ? (
    <div className="tooltip" title={tooltipText}>
      <IconComponent />
    </div>
  ) : null;
};

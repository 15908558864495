import * as React from 'react';
import { useEffect } from 'react';
import { useAuth } from './utils/auth-context'; // Ajusta la ruta según tu estructura
import { ViewTable } from './user-request/view-table';

export default function BaseComponent(): React.JSX.Element {
  const { ogmioToken, setOgmioToken } = useAuth();

  useEffect(() => {
    const myEventHandlingFunction = e => {
      setOgmioToken(e.detail.tokenValue);
    };

    window.addEventListener('userToken', myEventHandlingFunction);

    if (ogmioToken === '') {
      window.dispatchEvent(new CustomEvent('requestToken'));
    }

    return () => {
      window.removeEventListener('userToken', myEventHandlingFunction);
    };
  }, []);

  return (
    <div className="bg-gray-200 text-gray-300 min-h-screen">
      <ViewTable />
    </div>
  );
}

import { createContext, useContext, useState } from 'react';

const AuthContext = createContext({
  ogmioToken: '',
  setOgmioToken: (token: string) => {}
});

export const AuthProvider = ({ children }) => {
  const [ogmioToken, setOgmioToken] = useState('');

  return <AuthContext.Provider value={{ ogmioToken, setOgmioToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

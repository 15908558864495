export interface NotificationProject {
  to?: string;
  from?: string;
  date: string;
  currentstatus?: NotificationStatus;
  email?: string;
  role?: string;
  limit?: number | null;
  page?: number | null;
}

export interface FilterRequests {
  email?: string;
  role?: string;
  layer?: string;
  status?: NotificationStatus;
  dateRange?: string;
  limit?: number | null;
  page?: number | null;
}

export interface NotificationRequestInput {
  id: string;
  message?: string;
  userID?: string;
  newStatus?: NotificationStatus;
}

export enum NotificationStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  failed = 'failed'
}

export enum DateRangeFilter {
  sevenDays = '7days',
  oneMonth = '30days',
  twoMonth = '2month',
  oneYear = '1year'
}

export const LAYER_HACKER = 'LAYER_ADL_HACKERS';
export const LIMIT = 7;

import { useState, useEffect } from 'react';
import * as React from 'react';
import { useGetAllLayer, useGetAllRole } from '../hooks/useUserRequests';
import { FilterRequests, DateRangeFilter, NotificationStatus } from '../interfaces/userRequestI';

export interface FilterProps {
  onFilterChange: (filter: FilterRequests) => void;
}

export function Filter({ onFilterChange }: FilterProps): React.JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('email'); // Default to email
  const [dateRange, setDateRange] = useState(DateRangeFilter.sevenDays);
  const [status, setStatus] = useState(NotificationStatus.pending); // Estado inicial para el filtro de status
  const [layersFilter, setLayersFilter] = useState('');
  const [layers, setLayers] = useState([]);
  const [rolesFilter, setRolesFilter] = useState('');
  const [roles, setRoles] = useState([]);

  // Utiliza el hook para obtener todas las capas. Asumiendo que tienes un hook similar.
  const { data: layerData, isLoading: isLayerLoading } = useGetAllLayer();
  const { data: roleData, isLoading: isRoleLoading } = useGetAllRole();

  useEffect(() => {
    // Al cargar los datos de layer por primera vez
    if (layerData && !isLayerLoading && layerData.length > 0) {
      const firstLayerId = layerData[0].id;
      setLayers(layerData.map(layer => ({ id: layer.id, label: layer.value.label })));
      if (!layersFilter) {
        setLayersFilter(firstLayerId); // Solo si layersFilter no se ha establecido aún
      }
    }

    // Al cargar los datos de role por primera vez
    if (roleData && !isRoleLoading && roleData.length > 0) {
      const firstRoleId = roleData[0].id;
      setRoles(roleData.map(role => ({ id: role.id, label: role.value.singular })));
      if (!rolesFilter) {
        setRolesFilter(firstRoleId); // Solo si rolesFilter no se ha establecido aún
      }
    }
  }, [layerData, isLayerLoading, roleData, isRoleLoading, layersFilter, rolesFilter, dateRange, onFilterChange]);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setSearchTerm(email);

    if (email.length > 2) {
      onFilterChange({ email: email });
    }
    if (email.length === 0) {
      clearSearchTermAndRelaunch();
    }
  };

  const handleFilterByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterBy = e.target.value;
    setFilterBy(newFilterBy);

    onFilterChange({
      email: newFilterBy === 'email' ? searchTerm : undefined,
      dateRange,
      status: newFilterBy === 'status' ? NotificationStatus.pending : undefined,
      layer: newFilterBy === 'layer' ? layersFilter : undefined,
      role: newFilterBy === 'role' ? rolesFilter : undefined
    });
  };

  const handleDateRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDateRange = e.target.value as DateRangeFilter;
    setDateRange(newDateRange);
    onFilterChange({
      dateRange: newDateRange
    });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = e.target.value as NotificationStatus;
    setStatus(newStatus);
    onFilterChange({
      status: newStatus
    });
  };

  const handleLayerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLayer = e.target.value;
    setLayersFilter(newLayer);
    onFilterChange({ layer: newLayer, dateRange: dateRange });
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRole = e.target.value;
    setRolesFilter(newRole);
    onFilterChange({ role: newRole, dateRange: dateRange });
  };

  const clearSearchTermAndRelaunch = () => {
    setSearchTerm('');
    resetRequest(); // Llama a onFilterChange con searchTerm vacío y los otros filtros actuales
  };

  const resetRequest = () => {
    onFilterChange({
      email: undefined,
      dateRange: dateRange
    });
  };

  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <div className="flex">
          <div className="relative w-64">
            {filterBy === 'status' ? (
              <>
                <select
                  aria-label="Select Status"
                  className="border border-gray-300 relative px-4 py-2 rounded-l-md focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base w-64"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value={NotificationStatus.pending}>Pending</option>
                  <option value={NotificationStatus.failed}>Failed</option>
                  <option value={NotificationStatus.rejected}>Rejected</option>
                  <option value={NotificationStatus.approved}>Approved</option>
                </select>
              </>
            ) : filterBy === 'role' ? (
              <>
                <select
                  aria-label="Select Role"
                  className="border border-gray-300 relative px-4 py-2 rounded-l-md focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base w-64"
                  value={rolesFilter}
                  onChange={handleRoleChange}
                >
                  {roles.map(role => (
                    <option key={role.id} value={role.label} className="capitalize">
                      {role.label}
                    </option>
                  ))}
                </select>
              </>
            ) : filterBy === 'layer' ? (
              <>
                <select
                  aria-label="Select Layer"
                  className="border border-gray-300 relative px-4 py-2 rounded-l-md focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base w-64"
                  value={layersFilter}
                  onChange={handleLayerChange}
                >
                  {layers.map(layer => (
                    <option key={layer.id} value={layer.id}>
                      {layer.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded-l-md px-4 py-2 focus:outline-none focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button onClick={clearSearchTermAndRelaunch}>X</button>
                </div>
              </>
            )}
          </div>
          <div className="relative">
            <select
              aria-label="Filter by"
              className="border border-gray-300 relative px-4 py-2 rounded-r-md focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base w-32"
              value={filterBy}
              onChange={handleFilterByChange}
            >
              <option value="email">Email</option>
              <option value="layer">Layer</option>
              <option value="role">Role</option>
              <option value="status">Status</option>
            </select>
          </div>
        </div>
      </div>

      <div className="relative">
        <select
          aria-label="Date Range"
          className="border border-gray-300 relative px-6 py-2 rounded-xl focus:ring-vulcanus_delft_blue-700 focus:border-vulcanus_ring-vulcanus_delft_blue-700 text-black text-base"
          value={dateRange}
          onChange={handleDateRangeChange}
        >
          <option value={DateRangeFilter.sevenDays}>Last 7 days</option>
          <option value={DateRangeFilter.oneMonth}>Last 30 days</option>
          <option value={DateRangeFilter.twoMonth}>Last 2 month</option>
          <option value={DateRangeFilter.oneYear}>Last year</option>
        </select>
      </div>
    </div>
  );
}

export const getUserNotificationsByDateAndStatus = /* GraphQL */ `
  query getUserNotificationsByDateAndStatus($input: NotificationProject) {
    getUserNotificationsByDateAndStatus(input: $input) {
      data {
        id
        from
        to
        currentdate
        message
        currentstatus
        ticket
        comments
        request {
          id
          email
          layerId
          roleId
          tools {
            name
            value
          }
        }
        audit {
          statusPrev
          statusNew
          date
          from
          errors
        }
      }
      pageIndex
      pageSize
      totalPages
      totalItems
    }
  }
`;

export const getAllHefestoLayerConstants = /* GraphQL */ `
  query GetAllHefestoLayerConstants {
    getAllHefestoLayerConstants {
      id
      value {
        id
        label
        stack {
          id
          label
        }
      }
    }
  }
`;

export const getAllHefestoRoleConstants = /* GraphQL */ `
  query GetAllHefestoRoleConstants {
    getAllHefestoRoleConstants {
      id
      value {
        singular
      }
    }
  }
`;

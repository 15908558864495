import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef, useState } from 'react';

export interface RejectModalProps {
  isOpen: boolean;
  onClose: () => void;
  executeMutation: (msg: string) => void;
  description: string;
  title: string;
  loading: boolean;
  actionButtonText: string;
  secondaryButtonText: string;
}

export const RejectModal = (props: RejectModalProps) => {
  const [message, setMessage] = useState('');
  const cancelButtonRef = useRef(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.executeMutation(message);
    setMessage('');
    props.onClose();
  };

  const handleClose = () => {
    setMessage('');
    props.onClose();
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" initialFocus={cancelButtonRef} className="fixed inset-0 overflow-y-auto" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
            <div className="absolute inset-0 bg-gray-300 opacity-50"></div>
          </div>
        </Transition.Child>

        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmit} className="bg-white px-4 sm:p-6 sm:pb-4">
                <div className="text-base font-semibold leading-6 text-vulcanus_delft_blue">{props.title}</div>
                <div className="mb-4">
                  <label htmlFor="message" className="block text-gray-700 text-sm mb-2">
                    {props.description}
                  </label>
                  <textarea
                    id="message"
                    rows={3}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    required
                  />
                </div>
                <div className="flex items-center justify-end py-4 border-t border-gray-200 rounded-b space-x-4">
                  <button className="btn-approve" type="button" onClick={handleClose} disabled={props.loading}>
                    {props.secondaryButtonText}
                  </button>
                  <button className="btn-reject" type="submit" disabled={props.loading}>
                    {props.actionButtonText}
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
